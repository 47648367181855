* {
  box-sizing: border-box;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.winCircle {
  padding-top: 6px;
  color: #fff;
  width: 35px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
}

.failButton {
  background-color: #c8c7c8;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-8 {
  flex: 0 0 90%;
  max-width: 90%;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.sticky-topFrame {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  background-color: #ffff;
  z-index: 1020;
  border-top: 1px solid #dee2e6 !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}
.bg-white {
  background-color: #ffff;
}
.historyFont {
    font-weight: bold;
    font-size:35px;
}
.loader1 {
  display:inline-block;
  font-size:0px;
  padding:0px;
}
.loader1 span {
  vertical-align:middle;
  border-radius:100%;
  
  display:inline-block;
  width:10px;
  height:10px;
  margin:3px 2px;
  -webkit-animation:loader1 0.8s linear infinite alternate;
  animation:loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay:-1s;
  animation-delay:-1s;
 background:rgba(245, 103, 115,0.6);
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
 background:rgba(245, 103, 115,0.8);
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay:-0.26666s;
  animation-delay:-0.26666s;
 background:rgba(245, 103, 115,1);
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
 background:rgba(245, 103, 115,0.8);
 
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay:-1s;
  animation-delay:-1s;
 background:rgba(245, 103, 115,0.4);
}

.rules {
  font-family:Arial, Helvetica, sans-serif;
}

@keyframes loader1 {
  from {transform: scale(0, 0);}
  to {transform: scale(1, 1);}
}
@-webkit-keyframes loader1 {
  from {-webkit-transform: scale(0, 0);}
  to {-webkit-transform: scale(1, 1);}
}
